import { DateTime } from "luxon";

import type { LocationQueryValue } from "vue-router";
import {  useNuxtApp, useRuntimeConfig } from "#imports";


export const getLocale=():string => {
  
  const config = useRuntimeConfig();
  return config.public.locale;
}
/**
 * Format a date from a JS Date object
 * @param date - The date to format
 * @returns The formatted date as DD/MM/YYYY - ex: 03/07/2024
 */
const formatFromJsDate = (date: Date): string => {
  return DateTime.fromJSDate(date, { zone: "utc" }).setZone("Europe/Paris").setLocale(getLocale()).toFormat("dd/MM/yyyy");
};

/**
 * Format a date without time
 * @param dateString - The date to format
 * @returns The formatted date as DD/MM/YYYY - ex: 03/07/2024
 */
export const formatDate = (dateString: string): string => {
  return DateTime.fromISO(dateString, { zone: "utc" })
    .setZone("Europe/Paris")
    .setLocale(getLocale())
    .toFormat("dd/MM/yyyy");
};

/**
 * Format a date with time
 * @param dateString - The date to format
 * @returns The formatted date as DD/MM/YYYY HH:MM - ex: 03/07/2024 14:30
 */
export const formatDateWithTime = (dateString: string): string => {
  return DateTime.fromISO(dateString, { zone: "utc" })
    .setZone("Europe/Paris")
    .setLocale(getLocale())
    .toFormat("dd/MM/yyyy HH:mm");
};

/**
 * Format a time
 * @param dateString - The date to format
 * @returns The formatted time as HH:mm - ex: 14:30
 */
export const formatTime = (dateString: string): string => {
  return DateTime.fromISO(dateString, { zone: "utc" }).setZone("Europe/Paris").setLocale(getLocale()).toFormat("HH:mm");
};

/**
 * Format a date without timezone
 * @param date - The date to format
 * @returns The formatted date as DD/MM/YYYY - ex: 03/07/2024
 */
export const formatDateWithoutTimezone = (date: Date): string => {
  const offset = date.getTimezoneOffset();
  const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);

  return adjustedDate.toISOString().split("T")[0];
};

/**
 * Format a date to a written format with day and time
 * @param dateString - The date to format
 * @returns The formatted date as DD MMM - HHhMM - ex: 12 mai - 10h30
 */
export const formatWrittenDate = (dateString: string): string => {
  const date = DateTime.fromISO(dateString, { zone: "utc" }).setZone("Europe/Paris").setLocale(getLocale());

  const day = date.toFormat("d");
  const month = date.toFormat("MMMM");
  const time = date.toFormat("HH:mm").replace(":", "h");

  return `${day} ${month} - ${time}`;
};

/**
 * Format a time to a written format
 * @param dateString - The date to format
 * @returns The formatted time as HHhMM - ex: 10h30
 */
export const formatWrittenTime = (dateString: string): string => {
  const date = DateTime.fromISO(dateString, { zone: "utc" }).setZone("Europe/Paris");
  const time = date.toFormat("HH'h'mm").replace("h00", "h");

  return time;
};

export const isSameDay = (d1: Date, d2: Date): boolean => {
  return d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear();
};

export const formatDisplayDate = (range: { start: Date; end: Date }, isFullDay: boolean): string => {
  if (isFullDay) {
    return formatFromJsDate(range.start);
  }

  return `${formatFromJsDate(range.start)} - ${formatFromJsDate(range.end)}`;
};

export const parseDate = (date: LocationQueryValue | LocationQueryValue[]): Date | undefined => {
  return typeof date === "string" ? new Date(date) : undefined;
};
